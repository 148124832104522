// import moment from "moment";
import empty from "is-empty";
import api from "@api";
import { Routes } from "@constants";
/**
 * This is a collection of functions that mainly operate on
 * a user (or collection of users) and to return information
 * pertaining to that user (or set of users).
 *
 * @module UserHelpers
 */
var UserHelpers = {
  dashboardLink: function (user) {
    const dashboardLink = user.dashboardLink || "/emerg-room";
    const linkDash =
      dashboardLink[0] === "/" ? dashboardLink : `/${dashboardLink}`;
    return linkDash;
  },
  checkMail: (email) => {
    return api.get(Routes.verifyMail(email));
  },
  verifyEmail: (email) => {
    return api.get(Routes.verifyMail(email));
  },
  getUserByEmail: (email) => {
    return api.get(Routes.getUserByEmail(email));
  },
  getRoles: function (user) {
    if (!user || !user.roles) {
      return [];
    }
    return user.roles.map((r) => r.name);
  },
  getRoleName: function (user) {
    if (!user) {
      return "";
    }
    return UserHelpers.getRoles(user)[0];
  },
  hasCompleteInformation: function (user) {
    let is_complete = true;
    if (!user) {
      is_complete = false;
    }
    const requiredFileds = [
      "phoneNumber",
      "fullAddress",
      "birthday",
      "medicareNumber",
    ];
    requiredFileds.forEach((field) => {
      if (field === "medicareNumber") {
        if (
          (!user[field] || user[field] === "") &&
          !user["skipped_medicare_number"]
        )
          is_complete = false;
      } else {
        if (!user[field] || user[field] === "") is_complete = false;
      }
    });
    return is_complete;
  },

  // /**
  //  * Returns comma seperated list of a user' roles
  //  *
  //  * @param {Object} user
  //  * @method getRoleString
  //  * @return {String}
  //  */
  // getRoleString: function (user) {
  //   return compose(
  //     join(", "),
  //     map((role) => capitalize(role))
  //   )(UserHelpers.getRoles(user));
  // },
  // /**
  //  * Determins if a given user has any admin type role
  //  *
  //  * @param {Object} user
  //  * @method isAnyAdmin
  //  * @return {Boolean}
  //  */
  isAnyEditor: function (user) {
    const roles = UserHelpers.getRoles(user);
    return (
      roles.includes("admin") ||
      roles.includes("companyAdmin") ||
      roles.includes("nurse")
    );
  },
  isNurse: function (user) {
    const role = UserHelpers.getRoleName(user);
    return role === "nurse";
  },
  isInstantDoctor: function (user) {
    const role = UserHelpers.getRoleName(user);
    return role === "instantDoctor";
  },
  isCompanyAdmin: function (user) {
    const role = UserHelpers.getRoleName(user);
    return role === "companyAdmin";
  },
  /**
   * Determins if a given user is an admin
   *
   * @param {Object} user
   * @method isAdmin
   * @return {Boolean}
   */
  isAdmin: function (user) {
    const role = UserHelpers.getRoleName(user);

    return role === "admin";
  },
  /**
   * Determins if a given user is a doctor
   *
   * @param {Object} user
   * @method isDoctor
   * @return {Boolean}
   */
  isDoctor: function (user) {
    const role = UserHelpers.getRoleName(user);

    return role === "doctor";
    // return contains(RoleEnum.doctor)(UserHelpers.getRoles(user));
  },
  isDoctorOrAdmin: function (user) {
    return UserHelpers.isDoctor(user) || UserHelpers.isAdmin(user);
  },
  isAppointmentDoctor: function (user, appointment) {
    if (user && appointment) {
      return user.id === appointment.userId;
    }
    return false;
  },

  isAppointmentPatientOrNurse: function (user, appointment) {
    if (user && appointment) {
      return (
        user.id === appointment.patientId || user.id === appointment.nurseId
      );
    }
    return false;
  },
  appointmentRole: function (user, appointment) {
    if (!user || !appointment) {
      return null;
    }
    if (user.id == appointment.userId) {
      return "doctor";
    }
    if (user.id == appointment.patientId) {
      return "patient";
    }
    if (UserHelpers.isAdmin(user)) {
      return "admin";
    }
    return null;
  },
  isPatient: function (user) {
    console.log(user);
    const role = UserHelpers.getRoleName(user);
    return role === "patient";
  },

  /**
   * Converts a user object to the string version.
   * FristName LastName.
   *
   * @param {Object} user
   * @method userToNameString
   * @return {String} ex: => 'Robin Hood'
   */
  userToNameString: function (user) {
    if (!user) {
      return "";
    }
    let lastName = user.lastName ? ` ${user.lastName}` : "";
    return `${user.firstName}${lastName}`;
  },
  conversationDisplayName: function (user) {
    if (!user) {
      return "Unassigned";
    }
    let lastName = user.lastName ? ` ${user.lastName}` : "";
    return `${user.firstName}${lastName}`;
  },
  conversationDescription: function (conversation, recipient) {
    if (conversation.type.split("-")[0] == "emergency") {
      return "emergency consultation";
    }
    return conversation.specialty
      ? conversation.specialty.name
      : UserHelpers.specialtiesStr(recipient);
  },
  fullName: function (user) {
    if (empty(user)) {
      return "";
    }
    return this.userToNameString(user);
  },
  userPhoneNumber: function (user) {
    if (!user) {
      return "";
    }
    return `(${user.countryCode}) ${user.phoneNumber}`;
  },
  titleString: function (user) {
    if (!user || !user.title) {
      return "";
    }
    return user.title;
  },
  specialistName: function (user) {
    return `${UserHelpers.titleString(user)} ${user.lastName}`;
  },
  degreeString: function (user) {
    if (!user || !user.degree) {
      return "";
    }
    return user.degree;
  },
  /**
   * Converts a user specialty object to the string version.
   * specialty name.
   *
   * @param {Object} user
   * @method specialtiesStr
   * @return {String} ex: => ''
   */
  specialtiesStr: function (user) {
    if (!user || !user.specialties || user.specialties.length === 0) {
      return "";
    }
    let specialtiesNames = user.specialties.map(function (specialty) {
      return specialty.name;
    });
    return specialtiesNames.join(", ");
  },
  subscriptionPlan: function (user) {
    return user.permissions?.subscriptionPlan;
  },
  hasValidPayment: function (user) {
    if (user.paymentMethod) {
      return true;
    }
    return false;
  },
  hasBusinessTrialValidSubscription: function (user) {
    return user.permissions.hasBusinessTrialValidSubscription;
  },
  hasValidSubscription: function (user) {
    if (!user || !user.permissions) {
      return false;
    }
    return user.permissions.hasValidSubscription;
  },

  fetchPayments: function (userId) {
    return api.get(Routes.fetchPayments(userId));
  },
  hasPaymentErrors: function (user) {
    return user.permissions.paymentError;
  },
  isIncompleteProfileForPrescriptions: function (user) {
    return (
      empty(user.countryCode) ||
      empty(user.phoneNumber) ||
      empty(user.country) ||
      empty(user.region) ||
      empty(user.address) ||
      empty(user.address2) ||
      empty(user.postcode) ||
      empty(user.birthday)
    );
  },
  hasCreditCard: function (user) {
    return Boolean(user.paymentMethod);
  },
  updateUser: function (update, id) {
    return api.put(Routes.getUser(id), update);
  },
  getAddress: function (user) {
    if (!user) {
      return "";
    }
    if (user.fullAddress) {
      return user.fullAddress;
    }
    const address = empty(user.address) ? "" : user.address;
    const address2 = empty(user.address2) ? "" : `${user.address2}`;
    const region = empty(user.region) ? "" : `, ${user.region}`;
    const postcode = empty(user.postcode) ? "" : ` ${user.postcode}`;
    return `${address} ${address2}${region}${postcode}`;
  },
  getRecipient: function (appointment, user) {
    var role = user.roles[0];
    switch (role.name) {
      case "patient":
        return appointment.user;
      case "admin":
      case "doctor":
        return appointment.patientInfo;
      default:
        return appointment.user;
    }
  },

  getOwner: function (appointment, user) {
    if (!user) {
      return "unasigned";
    }
    if (user.id === appointment.patientId) {
      return appointment.patient;
    }
    return appointment.user;
  },
  fetchUserWithToken: async (userId, token) => {
    return await api.post(
      `/api/v1/users/${userId}/logInUser?accessToken=${token}` // Deprecated ?
    );
  },
  fetchUser: async (userId) => {
    return await api.get(Routes.getUser(userId));
  },
  fetchPatients: (params) => {
    return api.get(Routes.getPatients(params));
  },
  getSpecialtyInfoForUser: async (specialtyId, user) => {
    return await api.get(Routes.getSpecialtyInfoForUser(specialtyId, user?.id));
  },

  // hardDelete: (userID, token) => {
  //   return api.del(Routes.getUser(userID, token));
  // },

  block: (userID, block) => {
    return api.post(Routes.blockUser(userID), { block: block });
  },

  softDeleteUser: (userID, token) => {
    return api.del(Routes.softDeleteUser(userID, token));
  },
  mascaradeUser: (patient) => {
    return api.post(Routes.getUserAccessToken(patient.id), {});
  },

  fetchSubscriptionPlans: (user = {}) => {
    return api.get(Routes.fetchSubscriptionPlans(user.id));
  },
  fetchNotifications: (id, token) => {
    return api.get(Routes.getNotifications(id, token));
  },
  getUserWithSpecialties: async (userId) => {
    return await api.get(Routes.getUserWithSpecialties(userId));
  },
  getUserById: (userID, token) => {
    return api.get(Routes.getUserById(userID, token));
  },
  getDoctorAvailableSlots: (doctorId, dateFrom, times, duration, showAll) => {
    times = times || 1;
    (duration = duration || ""), (dateFrom = dateFrom || new Date());
    showAll = showAll || false;
    console.log({ showAll });
    return api.get(
      Routes.getDoctorAvailableSlots(
        doctorId,
        dateFrom,
        times,
        duration,
        showAll
      )
    );
  },
  changeEmail: (userId, email, pinCode) => {
    return api.post(Routes.changeEmail(userId), { email, pinCode });
  },
  getPatientsWithUserId: (userId) => {
    return api.get(Routes.getPatientsWithUserId(userId));
  },
  /**
   * Fetches the saved payment method for a user
   * @param {string} userId - The ID of the user
   * @returns {Promise} - Returns a promise that resolves to the payment method data
   */
  getPaymentMethod: async function (userId) {
    try {
      return await api.get(Routes.getPaymentMethod(userId));
    } catch (error) {
      console.error("Error fetching payment method:", error);
      return null;
    }
  },
};

export default UserHelpers;
