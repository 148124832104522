import api from "@api";
import Routes from "@constants/routes";

const Session = class Session {
  static async sendMagicLink(email, options = {}) {
    const domain = window.location.hostname;
    const defaultOptions = {
      path: null,
      nextSearch: null,
      nextFullRedirect: null,
      confirm: false,
      domain,
      email,
    };
    options = { ...defaultOptions, ...options };
    return await api.post(Routes.sendMagicLink(), options);
  }
  static async sendMagicVerificationLink(options) {
    return await api.post(Routes.sendMagicVerificationLink(), options);
  }

  static async login(email, password) {
    return await api.post(Routes.login, { email, password });
  }

  static async sendCreatePatient(options = {}, user = null) {
    const domain = window.location.hostname;
    const defaultOptions = {
      path: null,
      nextSearch: null,
      nextFullRedirect: null,
      confirm: false,
      domain,
      patient: user,
    };
    options = { ...defaultOptions, ...options };
    return await api.post(Routes.sendCreatePatient(), options);
  }
  static async sendConfirmationSignUp(email, data) {
    return await api.post(`${Routes.sendConfirmationSignUp(email)}`, data);
  }
  static async logout() {
    return await api.post(Routes.logout, {
      refreshToken: localStorage.getItem("refreshToken"),
    });
  }
  static async loginWithPinCode(email, pinCode) {
    return await api.post(Routes.loginWithPinCode(), { email, pinCode });
  }
  static async verifyPinCode(email, pinCode) {
    return await api.post(Routes.verifyPinCode(), { email, pinCode });
  }
  //TO REMOVE
  static async fetchUserWithToken(userId, token) {
    return await api.post(
      `/api/v1/users/${userId}/logInUser?accessToken=${token}`
    );
  }
  static async fethLoginUser() {
    return await api.get(Routes.fetchLoginUser());
  }

  static async googleLogin(IdToken, options = {}) {
    const defaultOptions = { confirm: false };
    options = { ...defaultOptions, ...options };
    return await api.post(Routes.googleLogin(), { IdToken, options });
  }
  static async sendPinCodeChangeEmail(options = {}) {
    const defaultOptions = {
      email: null,
      path: null,
      nextSearch: null,
      nextFullRedirect: null,
      confirm: false,
    };
    options = { ...defaultOptions, ...options };
    return await api.post(Routes.sendPinCodeChangeEmail(), options);
  }
};

export default Session;
