import queryString from "query-string";
import { Cookies } from "react-cookie";
const api = class api {
  // Store tokens in local storage (or session storage, depending on your needs)
  static setTokens(accessToken, refreshToken) {
    localStorage.setItem("accessToken", accessToken.token);
    localStorage.setItem("refreshToken", refreshToken.token);
  }

  static setMaskToken({ maskToken, currentToken }) {
    const currentTokenJSON = JSON.stringify(currentToken);
    localStorage.setItem("mascaradeToken", currentTokenJSON);
    localStorage.setItem("accessToken", maskToken.access.token);
    localStorage.setItem("refreshToken", maskToken.refresh.token);
  }

  // Retrieve tokens from local storage
  static getAccessToken() {
    return localStorage.getItem("accessToken");
  }

  static getRefreshToken() {
    return localStorage.getItem("refreshToken");
  }

  static getMaskToken() {
    const currentTokenJSON = localStorage.getItem("mascaradeToken");
    const currentToken = JSON.parse(currentTokenJSON);
    return currentToken;
  }

  // Clear tokens from storage
  static clearTokens() {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("mascaradeToken");
  }

  static clearMaskToken() {
    localStorage.removeItem("mascaradeToken");
  }

  static async refreshAccessToken() {
    if (!api.getRefreshToken()) {
      throw new Error("No refresh token found");
    }
    const refreshToken = api.getRefreshToken();
    const response = await fetch("/api/v1/auth/refresh-tokens", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ refreshToken }),
    });

    if (!response.ok) {
      api.clearTokens();
      throw new Error("Failed to refresh access token");
    }

    const data = await response.json();
    api.setTokens(data.access, data.refresh);
    return data.accessToken;
  }

  static async refreshTokenWithMagicLinkToken(magicToken) {
    const response = await fetch("/api/v1/auth/magic-tokens", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ magicToken }),
    });

    console.log(response);

    if (!response.ok) {
      throw new Error("Failed to refresh access token");
    }

    const data = await response.json();
    api.setTokens(data.access, data.refresh);
    return data.accessToken;
  }

  static async refreshTokenWithCreatePatientToken(createPatientToken) {
    const response = await fetch("/api/v1/auth/create-patient-tokens", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ createPatientToken }),
    });

    if (!response.ok) {
      throw new Error("Failed to refresh access token");
    }

    const data = await response.json();
    api.setTokens(data.access, data.refresh);
    return data.accessToken;
  }

  static async get(route, params, options = {}) {
    const getOptions = {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    };
    const opts = { ...getOptions, ...options };
    if (params) {
      const qparams = new URLSearchParams(params);
      route = `${route}?${qparams}`;
    }
    return api.rawRequest(route, opts);
  }
  static async getBlob(route, options = {}) {
    let accessToken = api.getAccessToken(); // Fetch the access token
    const getOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`, // Add Bearer token to Authorization header
      },
      ...options, // Merge any additional options
    };

    const fetchBlob = async (requestOptions) => {
      const response = await fetch(route, requestOptions);

      // Handle token expiration (401)
      if (response.status === 401) {
        try {
          // Attempt to refresh the token
          accessToken = await api.refreshAccessToken();
          requestOptions.headers.Authorization = `Bearer ${accessToken}`; // Update header with new token
          return fetchBlob(requestOptions); // Retry request with refreshed token
        } catch (error) {
          api.clearTokens(); // Clear tokens if refresh fails
          throw new Error("Authentication failed"); // Throw an error if unable to refresh token
        }
      }

      // If response is not okay, throw an error
      if (!response.ok) {
        const errorMessage = response.statusText || "Unknown error occurred";
        throw new Error(errorMessage);
      }

      const blob = await response.blob();

      return blob; // Return the Blob object directly
    };

    return fetchBlob(getOptions);
  }

  static async post(route, data = {}, options = {}) {
    const getOptions = {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "default", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    };
    const opts = { ...getOptions, ...options };
    return api.rawRequest(route, opts);
  }

  static async multiPart(route, data = {}, options = {}) {
    const getOptions = {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "default", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {},
      body: data,
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    };
    const opts = { ...getOptions, ...options };
    return api.rawRequest(route, opts);
  }

  static async put(route, data = {}, options = {}) {
    const getOptions = {
      method: "PUT", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "default", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    };
    const opts = { ...getOptions, ...options };
    return api.rawRequest(route, opts);
  }

  static async del(route, data = {}, options = {}) {
    const getOptions = {
      method: "DELETE", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "default", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    };
    const opts = { ...getOptions, ...options };
    return api.rawRequest(route, opts);
  }
  static async rawRequest(route, options) {
    let accessToken = api.getAccessToken();
    options.headers.Authorization = `Bearer ${accessToken}`;
    let response = await fetch(route, options);
    console.log("response", response);

    let jsonres = await response.json();
    console.log("response", { response, jsonres });

    if (response.status === 401) {
      try {
        accessToken = await api.refreshAccessToken();
        return api.rawRequest(route, options);
      } catch (error) {
        api.clearTokens();
        throw new Error("Authentication failed");
      }
    }
    if (!response.ok) {
      const errorMessage =
        jsonres?.message || response?.statusText || "unknowError";
      const error = new Error(errorMessage);
      error.response = JSON.stringify(jsonres);
      error.jsonres = jsonres;
      throw error;
    }
    return jsonres;
  }
};

export default api;
